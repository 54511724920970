import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import CONFIG from 'config/configProps';
import {
  isFetchRequired,
  selectedDates,
  getPostDataForFilters,
  isEmptyOrNull,
  isLeaderRole,
} from 'utils';
import './index.scss';
import KTFilters from './KTFilters';
import { isEmpty, isEqual } from 'utils/common';
import { AuthorizeHoc } from 'components/common/authorizeHOCPt/authorizeHOC';
// DEFAULT STATE

const { startDate, endDate } = selectedDates(
  new Date(),
  '',
  'Last 12 Months',
  true
);

const DEFAULT_STATE = {
  dirty: false,
  startDate: startDate,
  endDate: endDate,
  isBusinessComparisonPage: false,
};

/**
 * Alias for Filters
 */
const filterTextField = CONFIG.PROTOTYPE_FILTERS;

class FilterSection extends PureComponent {
  //#region STATIC Methods

  /**
   * Static Method to Prepare the Default State using the Filters Selected by User.
   * This is used to set the filters on FilterSection whenever user deletes an
   * applied filter or URl changes
   * @param {*} userSelectedFilters appliedFilters in case search
   */
  static prepareDefaultState(userSelectedFilters) {
    const defaultState = DEFAULT_STATE;
    defaultState[filterTextField.PRACTICE_AREA.field] =
      FilterSection.prepareStateValue(
        filterTextField.PRACTICE_AREA.field,
        userSelectedFilters,
        []
      );
    defaultState[filterTextField.LOCATION.field] =
      FilterSection.prepareStateValue(
        filterTextField.LOCATION.field,
        userSelectedFilters,
        []
      );
    defaultState[filterTextField.START_DATE.field] =
      FilterSection.prepareStateValue(
        filterTextField.START_DATE.field,
        userSelectedFilters,
        defaultState.startDate
      );
    defaultState[filterTextField.END_DATE.field] =
      FilterSection.prepareStateValue(
        filterTextField.END_DATE.field,
        userSelectedFilters,
        defaultState.endDate
      );
    defaultState[filterTextField.NAME.field] = FilterSection.prepareStateValue(
      filterTextField.NAME.field,
      userSelectedFilters,
      []
    );
    defaultState[filterTextField.PATH.field] = FilterSection.prepareStateValue(
      filterTextField.PATH.field,
      userSelectedFilters,
      []
    );
    defaultState[filterTextField.STATUS.field] =
      FilterSection.prepareStateValue(
        filterTextField.STATUS.field,
        userSelectedFilters,
        []
      );
    defaultState[filterTextField.JOB_TITLE.field] =
      FilterSection.prepareStateValue(
        filterTextField.JOB_TITLE.field,
        userSelectedFilters,
        []
      );

    defaultState[filterTextField.MANAGEMENT_MODEL.field] =
      FilterSection.prepareStateValue(
        filterTextField.MANAGEMENT_MODEL.field,
        userSelectedFilters,
        []
      );
    defaultState[filterTextField.GEO_BUSINESS.field] =
      FilterSection.prepareStateValue(
        filterTextField.GEO_BUSINESS.field,
        userSelectedFilters,
        []
      );
    defaultState[filterTextField.WEEK_POSTED_DATE.field] =
      FilterSection.prepareStateValue(
        filterTextField.WEEK_POSTED_DATE.field,
        userSelectedFilters,
        []
      );
    return defaultState;
  }

  /**
   * method to assign either selected value or default value to the specific Filter field/State
   * @param {*} key filterFieldKey
   * @param {*} filterStateObject filter Object
   * @param {*} defaultValue default Value in case no value was found in filters
   */
  static prepareStateValue(key, filterStateObject, defaultValue) {
    let resultantStateValue = filterStateObject?.[key] || defaultValue;
    return resultantStateValue;
  }

  /**
   * Lifecycle method: Get Derived State from Props
   * @param {*} props props
   * @param {*} state state
   */
  static getDerivedStateFromProps(props, state) {
    const {
      appliedFilters,
      selectedIndexFromStore,
      appliedFiltersBc,
      loggedInUserRole,
      history,
    } = props;
    const isBusinessComparisonPage =
      isEqual(window.location.pathname, CONFIG.PROTOTYPE_URLS.BILLING) &&
      isEqual(
        history.location?.state?.optionIndex || selectedIndexFromStore,
        0
      ) &&
      isLeaderRole(loggedInUserRole);
    if (isBusinessComparisonPage && !state.isBusinessComparisonPage) {
      return {
        ...FilterSection.prepareDefaultState(appliedFiltersBc),
        isBusinessComparisonPage: true,
      };
    } else if (!isBusinessComparisonPage) {
      return {
        ...FilterSection.prepareDefaultState(appliedFilters),
      };
    }
  }

  /**
   * constructor of the Component.
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      dirty: props.dirty,
    };
    this.bindClassMethods();
  }

  componentDidMount() {
    const {
      actions: {
        fetchFiltersData,
        fetchUserData,
        fetchFiltersDataBc,
        fetchSavedCollection,
      },
      jobTitleList,
      practiceAreaList,
      locationList,
      memberList,
      geoLocationList,
      userDetails,
      defaultUserFilters,
      jobTitleListBc,
      practiceAreaListBc,
      locationListBc,
      memberListBc,
      geoLocationListBc,
      loggedInUserRole,
      loggedInUserId,
      savedCollection,
    } = this.props;
    let postData = { ...this.state };
    delete postData.dirty;
    postData = getPostDataForFilters(userDetails.results, postData);
    fetchUserData(isFetchRequired(memberList), {
      employeeIdHRO: loggedInUserId,
      startDate: postData.startDate,
      endDate: postData.endDate,
      dateFilterType: postData.dateFilterType,
    });
    postData.employeeIdHRO = loggedInUserId;
    fetchSavedCollection(isFetchRequired(savedCollection));
    fetchFiltersData(
      isFetchRequired(practiceAreaList),
      isFetchRequired(geoLocationList),
      isFetchRequired(jobTitleList),
      isFetchRequired(locationList),
      isFetchRequired(defaultUserFilters),
      postData
    );

    if (isLeaderRole(loggedInUserRole))
      fetchFiltersDataBc(
        isFetchRequired(practiceAreaListBc),
        isFetchRequired(geoLocationListBc),
        isFetchRequired(jobTitleListBc),
        isFetchRequired(locationListBc),
        isFetchRequired(memberListBc),
        { startDate: startDate, endDate: endDate },
        loggedInUserId
      );
  }

  /**
   * helper method to bind the CLass methods with the Component Context
   */
  bindClassMethods = () => {
    this.handleDateFilterChange = this.handleDateFilterChange.bind(this);
    this.handleDropdownSelectionChange =
      this.handleDropdownSelectionChange.bind(this);
    this.handleTreeSelectedApply = this.handleTreeSelectedApply.bind(this);
    this.handleFilterStateChange = this.handleFilterStateChange.bind(this);
  };

  /**
   * helper method to set the LOCAL state of this component
   */
  updateState = (stateField, newValue) => {
    const newState = { ...this.state };
    newState[stateField] = newValue;
    this.setState({
      ...newState,
      dirty: true,
    });
  };

  isHomePage = () => {
    return isEqual(
      this.props.history.location.pathname,

      CONFIG.PROTOTYPE_URLS.HOME
    );
  };

  handleReduxChanges(fetchApiData, postData) {
    const {
      actions: {
        fetchFiltersData,
        fetchUserData,
        resetData,
        fetchFiltersDataBc,
      },
      loggedInUserId,
    } = this.props;
    delete postData.dirty;
    this.setState({
      jobTitle: fetchApiData.jobTitle ? [] : this.state.jobTitle,
      homeOffice: fetchApiData.location ? [] : this.state.homeOffice,
      selectedUserId: fetchApiData.memberList ? [] : this.state.selectedUserId,
    });
    if (this.state.isBusinessComparisonPage) {
      fetchFiltersDataBc(
        false,
        false,
        fetchApiData.jobTitle,
        fetchApiData.location,
        fetchApiData.memberList,
        { ...postData },
        loggedInUserId
      );
    } else {
      fetchFiltersData(
        false,
        false,
        fetchApiData.jobTitle,
        fetchApiData.location,
        false,
        { ...postData, employeeIdHRO: loggedInUserId }
      );
      fetchUserData(fetchApiData.memberList, {
        ...postData,
        employeeIdHRO: loggedInUserId,
      });
    }

    resetData(true);
  }

  /**
   * handler for the Open Date Filter Change.
   * @param {*} selectedDateRange Selected Date Range as Array. Array[0] will be 'from' and Array[1] will be 'to'.
   */
  handleDateFilterChange(startDate, endDate, filterType) {
    const { appliedFilters, userDetails } = this.props;
    const newState = { ...this.state };
    newState['startDate'] = startDate;
    newState['endDate'] = endDate;
    newState['dateFilterType'] = filterType;
    newState[filterTextField.WEEK_POSTED_DATE.field] = [];
    newState['allWeekPostedDates'] = [];
    this.setState(
      {
        ...newState,
        dirty: true,
      },
      () => {
        const postData = { ...newState };
        if (isEmpty(postData.selectedUserId) && isEmpty(appliedFilters)) {
          postData.selectedUserId = [userDetails.results.hrid];
        } else if (isEmpty(postData.selectedUserId)) {
          postData.selectedUserId = this.props.appliedFilters.selectedUserId;
        }
        this.handleReduxChanges(
          {
            memberList: false,
            jobTitle: false,
            location: false,
            isNameFilterClicked: true,
          },
          postData
        );
      }
    );
  }

  /**
   * handle the Selection Change for Advanced Filters.
   * @param {*} dropdownIdentifier Identifier for current Advanced Filter
   * @param {*} selectedValue Selected Value(s)
   */
  handleDropdownSelectionChange(
    dropdownIdentifier,
    selectedValue,
    passPa,
    passGeo
  ) {
    let fetchApiData = { jobTitle: false, location: false, memberList: false };
    this.updateState(dropdownIdentifier, selectedValue);
    let postData = { ...this.state };
    postData[dropdownIdentifier] = selectedValue;
    const { data } = this.props.defaultUserFilters;
    if (passGeo) {
      this.updateState(filterTextField.GEO_BUSINESS.field, data?.geoRegion);
      postData[filterTextField.GEO_BUSINESS.field] = data?.geoRegion;
    }
    if (passPa) {
      postData = { ...postData, ...this.getPracticeArea(data) };
    }
    const {
      actions: { updateIsMemberFilterSelected, saveAppliedCollectionName },
    } = this.props;
    switch (dropdownIdentifier) {
      case filterTextField.PATH.field:
      case filterTextField.MANAGEMENT_MODEL.field:
      case filterTextField.GEO_BUSINESS.field:
        fetchApiData = { jobTitle: true, location: true, memberList: true };
        postData[filterTextField.NAME.field] = [];
        postData[filterTextField.LOCATION.field] = [];
        postData[filterTextField.JOB_TITLE.field] = [];
        break;
      case filterTextField.JOB_TITLE.field:
        fetchApiData = { jobTitle: false, location: true, memberList: true };
        postData[filterTextField.NAME.field] = [];
        postData[filterTextField.LOCATION.field] = [];
        break;
      case filterTextField.STATUS.field:
        fetchApiData = { jobTitle: false, location: false, memberList: true };
        postData[filterTextField.NAME.field] = [];
        break;
      case filterTextField.NAME.field:
        fetchApiData = {
          jobTitle: false,
          location: false,
          memberList: false,
          isNameFilterClicked: true,
        };
        if (isEmptyOrNull(selectedValue)) {
          updateIsMemberFilterSelected(false);
          saveAppliedCollectionName('');
        } else updateIsMemberFilterSelected(true);
        break;
      default:
        return;
    }
    this.handleReduxChanges(fetchApiData, postData);
  }

  handleTreeSelectedApply = (
    dropdownIdentifier,
    selectedObject,
    passPa,
    passGeo
  ) => {
    const resetLocation = isEqual(
      dropdownIdentifier,
      filterTextField.PRACTICE_AREA.field
    );
    let newState = { ...this.state };
    const { data } = this.props.defaultUserFilters;
    if (passGeo) {
      newState['geoRegion'] = data?.geoRegion;
    }
    if (
      isEqual(dropdownIdentifier, filterTextField.PRACTICE_AREA.field) ||
      passPa
    ) {
      let newObject = passPa ? data : selectedObject;
      newState = { ...newState, ...this.getPracticeArea(newObject) };
    }
    if (isEqual(dropdownIdentifier, filterTextField.WEEK_POSTED_DATE.field)) {
      newState['weekPostedDates'] = selectedObject.weekPostedDates;
      newState['allWeekPostedDates'] = selectedObject.allWeekPostedDates;
    } else {
      newState[dropdownIdentifier] = selectedObject[dropdownIdentifier];
    }
    this.setState(
      {
        ...newState,
        dirty: true,
      },
      () => {
        let fetchApiData = {
          memberList: true,
          jobTitle: true,
          location: resetLocation,
        };
        const postData = { ...newState };
        postData[filterTextField.NAME.field] = [];
        postData[filterTextField.JOB_TITLE.field] = [];
        if (resetLocation) {
          postData[filterTextField.LOCATION.field] = [];
        }
        this.handleReduxChanges(fetchApiData, postData);
      }
    );
  };

  getPracticeArea = data => {
    let newState = {};
    newState['paIds'] = data?.paIds;
    newState['paAll'] = data?.paAll;
    newState['topicSectorAll'] = data?.topicSectorAll;
    newState['subSectorAll'] = data?.subSectorAll;
    newState['topicSectorIds'] = data?.topicSectorIds;
    newState['subSectorIds'] = data?.subSectorIds;
    return newState;
  };

  handleFilterStateChange = state => {
    const {
      actions: { updateIsFilterOpen },
    } = this.props;
    updateIsFilterOpen(state);
  };

  /**
   * Render LIFECYCLE METHOD
   */
  render() {
    return (
      <KTFilters
        defaultValue={this.state}
        onSelectionChange={this.handleDropdownSelectionChange}
        onDateSelectionChange={this.handleDateFilterChange}
        onTreeSelectApply={this.handleTreeSelectedApply}
        updateFilterState={this.handleFilterStateChange}
        isHomePage={this.isHomePage()}
        isBusinessComparisonPage={this.state.isBusinessComparisonPage}
        {...this.props}
      />
    );
  }
}

export default withRouter(AuthorizeHoc(FilterSection));
