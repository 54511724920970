import React, { useState } from 'react';
import { isEmptyOrNull, dropdownFormat } from 'utils';
import { Modal } from 'semantic-ui-react';
import Button from 'components/common/button/Button';
import { isEqual, times } from 'utils/common';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'redux/actions';
import './index.scss';
import SaveCollection from './saveCollection';

const DisplayCollection = props => {
  const collectionList = useSelector(
    state => state.filterDataPt.fetchCollectionData?.results || {}
  );

  const appliedCollectionName = useSelector(
    state => state.filterDataPt.appliedCollectionName
  );

  const dispatch = useDispatch();

  const [showEditDeletePopUp, setShowEditDeletePopUp] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState({});
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const [isEditModeOn, setIsEditModeOn] = useState(false);
  const [selectedUserList, setSelectedUserList] = useState([]);
  const [selectedUserDisplayList, setSelectedUserDisplayList] = useState([]);

  const onDeleteClick = () => {
    dispatch(actions.protoTypeActions.deleteCollection(selectedCollection.id));
    setShowDeletePopUp(false);
    setShowEditDeletePopUp(false);
    setIsEditModeOn(false);
    setSelectedCollection({});
  };

  const applyCollection = selectedCollection => {
    setSelectedCollection(selectedCollection);
    dispatch(
      actions.protoTypeActions.saveAppliedCollection(selectedCollection.name)
    );
    props.applyCollection(
      selectedCollection?.userSelectedFilterDTO?.selectedUserDetails
    );
  };

  const handleEditModeOn = collection => {
    const selectedUserList =
      collection?.userSelectedFilterDTO?.selectedUserDetails || [];
    const selectedList = selectedUserList.map(
      employee => employee.employeeIdHRO
    );
    setSelectedCollection(collection);
    setSelectedUserList(selectedList);
    setIsEditModeOn(true);
    setSelectedUserDisplayList(
      selectedUserList?.map(({ employeeIdHRO, employeeName }) =>
        dropdownFormat('' + employeeIdHRO, employeeName)
      )
    );
  };

  return (
    <>
      {!isEmptyOrNull(collectionList) && (
        <div className="collection-list">
          {collectionList?.userFilterCollectionDTO.map(collection => {
            return (
              <div
                className={`collection-item ${
                  isEqual(appliedCollectionName, collection.name)
                    ? 'active'
                    : ''
                }`}
                key={collection.id}
                onClick={() => applyCollection(collection)}
              >
                <div className="collection-name">{collection.name}</div>
                <div
                  className="collection-icon"
                  onClick={e => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    setShowEditDeletePopUp({
                      [collection.id]: !showEditDeletePopUp[collection.id],
                    });
                  }}
                >
                  {times(3, i => (
                    <div key={i}>.</div>
                  ))}
                </div>
                {showEditDeletePopUp[collection.id] && (
                  <div className="edit-delete">
                    <div
                      className="edit"
                      onClick={e => {
                        e.stopPropagation();
                        handleEditModeOn(collection);
                      }}
                    >
                      Edit
                    </div>
                    <div
                      className="delete"
                      onClick={e => {
                        e.stopPropagation();
                        setShowDeletePopUp(true);
                        setSelectedCollection(collection);
                      }}
                    >
                      Delete
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
      {isEditModeOn && (
        <SaveCollection
          selectedList={selectedUserList}
          memberList={props.memberList}
          selectedDropDownList={selectedUserDisplayList}
          isEditModeOn={isEditModeOn}
          selectedCollection={selectedCollection}
          closeModal={props.closeModal}
          deleteCollection={() => setShowDeletePopUp(true)}
          applyFilter={props.applyFilter}
        />
      )}
      <Modal
        closeIcon
        className="save_filter_modal"
        size="tiny"
        open={showDeletePopUp}
        onClose={() => setShowDeletePopUp(false)}
      >
        <Modal.Header className="save_filter_modal__header">
          Delete
        </Modal.Header>
        <Modal.Content className="save_filter_modal__content admin-filter-container">
          {`Are you sure you want to delete collection "${selectedCollection?.name}"?`}
        </Modal.Content>
        <Modal.Actions className="save_filter_modal__footer">
          <Button
            onClick={() => onDeleteClick()}
            className="save_filter_modal__footer__download-btn"
            content="Delete"
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default DisplayCollection;
